export type RecoverOptions = 'username' | 'hash'

export interface IForgotPasswordRequest {
  email?: string
  recover?: RecoverOptions
}

export class ForgotPasswordRequest {
  public email: string = ''
  public recover?: RecoverOptions | undefined

  constructor ({ email = '', recover }: IForgotPasswordRequest) {
    this.email = email
    this.recover = recover
  }

  public get isValid() {
    return !!this.email && !!this.recover
  }

  public get recoverOptions(): DictionaryByKeys<RecoverOptions, string> {
    return {
      username: 'Recover username',
      hash: 'Recover password'
    }
  }
}
