


















































import { AuthService } from '@/services/auth'
import { Route } from 'vue-router'
import { stateModule, userModule } from '@/store'
import { Component, Vue } from 'vue-property-decorator'

import Loader from '@/components/loaders/Loader.vue'
import TextInput from '@/components/inputs/Text.vue'
import RadioButton from '@/components/inputs/Radio.vue'

import { ForgotPasswordRequest, RecoverOptions } from '@/requests/auth/ForgotPasswordRequest'

const authService: AuthService = new AuthService()

@Component({
  components: {
    Loader,
    TextInput,
    RadioButton
  },
})
export default class Forgot extends Vue {
  private submitted: boolean = false

  private body: ForgotPasswordRequest = new ForgotPasswordRequest({})
  private message: string = ''

  private errors: ErrorResponse = {}

  private get appVersion(): string {
    return stateModule.version
  }

  private get loading(): boolean {
    return stateModule.loading
  }

  private destroyed(): void {
    if (this.submitted) {
      stateModule.setLoading(false)
    }
  }

  private selectInput(value: RecoverOptions) {
    this.body.recover = value
  }

  private resetError(key: string): void {
    this.$set(this.errors, key, [])
  }

  private async submit(): Promise<void> {
    if (!this.body.isValid) return
    stateModule.setLoading(true)
    this.$set(this, 'errors', {})
    try {
      const { data } = await authService.forgotPassword(this.body)
      this.submitted = true
      this.message = data
    } catch (e) {
      if (e.message && e.errors) {
        this.$set(this, 'errors', { message: e.message, ...e.errors })
      } else if (e.errors) {
        this.$set(this, 'errors', e.errors)
      } else if (e.message) {
        this.$set(this, 'errors', { message: e.message })
      }
    } finally {
      stateModule.setLoading(false)
    }
  }
}
